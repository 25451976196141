<template>
  <div class="member_wrap">
    <div class="member_top">
      램 일반회원가입
      <router-link to="/member/terms">
        <img src="@/assets/images/sub/back_ico.png" alt="" />
      </router-link>
    </div>
    <ValidationObserver ref="obServer">
      <form @submit.prevent="regist">
        <div class="member_box">
          <div class="mem_input">
            <div class="box">
              <h3>아이디</h3>
              <ValidationProvider
                ref="userid"
                :rules="{
                  required: true,
                  min_length: 6,
                  uniqId: true,
                }"
                v-slot="{ errors }"
                name="아이디"
              >
                <div class="input type_check">
                  <input
                    type="text"
                    name="userid"
                    maxlength="20"
                    v-model="userid"
                  />
                  <router-link to="" @click.native="dupleId('userid')">
                    <span>중복확인</span>
                  </router-link>
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>이메일</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                  email: true,
                }"
                v-slot="{ errors }"
                name="E-mail"
              >
                <div class="input">
                  <input
                    type="text"
                    name="email"
                    maxlength="64"
                    v-model="email"
                  />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>비밀번호</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                  min_length: 8,
                }"
                v-slot="{ errors }"
                name="비밀번호"
                vid="password"
              >
                <div class="input">
                  <input
                    type="password"
                    name="password"
                    maxlength="20"
                    v-model="password"
                  />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>비밀번호 확인</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                  min_length: 8,
                  confirmed: 'password',
                }"
                v-slot="{ errors }"
                name="비밀번호 확인"
              >
                <div class="input">
                  <input
                    type="password"
                    name="re_password"
                    maxlength="20"
                    v-model="re_password"
                  />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>이름</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                name="이름"
              >
                <div class="input">
                  <input
                    type="text"
                    name="user_name"
                    maxlength="20"
                    v-model="user_name"
                  />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>일반 채널 닉네임</h3>
              <ValidationProvider
                ref="nickname"
                :rules="{
                  required: true,
                  min_length: 3,
                  uniqNickname: true,
                  vaildNickname: true,
                }"
                v-slot="{ errors }"
                name="닉네임"
              >
                <div class="input type_check">
                  <input
                    type="text"
                    name="nickname"
                    maxlength="10"
                    v-model="nickname"
                  />
                  <router-link to="" @click.native="dupleNickname('nickname')">
                    <span>중복확인</span>
                  </router-link>
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>생년월일</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                name="생년월일"
              >
                <div class="birth-day">
                  <date-picker
                    :date="startTime"
                    :option="option"
                    @change="updateBirthday"
                    v-model="birthday"
                  ></date-picker>
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <!-- <span>년</span>
                  <input type="text" name="" class="month" /><span>월</span>
                  <input type="text" name="" class="day" /><span>일</span> -->
            </div>
            <div class="box">
              <h3>성별</h3>
              <div class="input">
                <select name="sex" v-model="sex">
                  <option value="">선택</option>
                  <option value="male">남</option>
                  <option value="female">여</option>
                </select>
              </div>
            </div>
          </div>
          <div class="mem_btn">
            <router-link to="" @click.native="regist">완 료</router-link>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from "vuex";
import myDatepicker from "vue-datepicker";
export default {
  data() {
    return {
      startTime: {
        time: "",
      },
      endtime: {
        time: "",
      },
      option: {
        type: "day",
        week: ["월", "화", "수", "목", "금", "토", "일"],
        month: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        format: "YYYY-MM-DD",
        placeholder: "",
        color: {
          header: "#ffd040",
          headerText: "#000",
        },
        buttons: {
          ok: "확인",
          cancel: "취소",
        },
        overlayOpacity: 0.3, // 0.5 as default
        dismissible: true, // as true as default
      },
      userid: null,
      email: null,
      password: null,
      re_password: null,
      user_name: null,
      nickname: null,
      sex: "",
      birthday: null,
      mtype: "member",
    };
  },
  components: {
    "date-picker": myDatepicker,
  },

  computed: {
    ...mapState("member", [
      "result",
      "msg",
      "finance",
      "mailing",
      "sms",
      "service",
      "privacy",
      "over14",
      "isCheckPhone",
    ]),
  },
  created() {
    if (!this.service) {
      this.$toast.default("서비스 이용약관에 동의 해주세요.");
      this.$router.push("/member/terms");
      return false;
    }
    // if (!this.finance) {
    //   this.$toast.default("전자금용 이용약관에 동의 해주세요.");
    //   this.$router.push("/member/terms");
    //   return false;
    // }
    if (!this.privacy) {
      this.$toast.default("개인정보 수집 이용 동의약관에 동의 해주세요.");
      this.$router.push("/member/terms");
      return false;
    }
    if (!this.over14) {
      this.$toast.default(
        "만 14세 미만 아동의 램 일반 회원 가입을 불허합니다."
      );
      this.$router.push("/member/terms");
      return false;
    }
    if (!this.isCheckPhone) {
      this.$toast.default("휴대폰번호 인증이 필요합니다.");
      this.$router.push("/member/auth_phone");
      return false;
    }
  },
  methods: {
    async regist() {
      const validate = await this.$refs.obServer.validate();
      if (validate) {
        await this.$store.dispatch("member/registerOk", {
          userid: this.userid,
          email: this.email,
          password: this.password,
          re_password: this.re_password,
          user_name: this.user_name,
          nickname: this.nickname,
          birthday: this.birthday,
          sex: this.sex,
          mtype: this.mtype,
          mailing: this.mailing ? "y" : "n",
          sms: this.sms ? "y" : "n",
        });
        if (this.result) {
          this.$toast.default(this.msg);
          this.$store.dispatch("member/clearUserInfoAll");
          this.$router.push("/member/login");
        } else {
          this.$toast.default(this.msg);
        }
      }
    },
    updateBirthday(birthday) {
      this.birthday = birthday;
    },
    dupleId(filed) {
      const provider = this.$refs[filed];

      // Validate the field
      provider.validate().then((result) => {
        if (result.valid) {
          this.$toast.default("사용가능한 ID 입니다.");
        } else {
          this.$toast.default(result.errors[0]);
        }
      });
    },
    dupleNickname(filed) {
      const provider = this.$refs[filed];

      // Validate the field
      provider.validate().then((result) => {
        if (result.valid) {
          this.$toast.default("사용가능한 닉네임 입니다.");
        } else {
          this.$toast.default(result.errors[0]);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mem_btn {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  a {
    height: 50px !important;
    line-height: 50px !important;
  }
}
</style>
