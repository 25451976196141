var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"member_wrap"},[_c('div',{staticClass:"member_top"},[_vm._v(" 램 일반회원가입 "),_c('router-link',{attrs:{"to":"/member/terms"}},[_c('img',{attrs:{"src":require("@/assets/images/sub/back_ico.png"),"alt":""}})])],1),_c('ValidationObserver',{ref:"obServer"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.regist.apply(null, arguments)}}},[_c('div',{staticClass:"member_box"},[_c('div',{staticClass:"mem_input"},[_c('div',{staticClass:"box"},[_c('h3',[_vm._v("아이디")]),_c('ValidationProvider',{ref:"userid",attrs:{"rules":{
                required: true,
                min_length: 6,
                uniqId: true,
              },"name":"아이디"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"input type_check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userid),expression:"userid"}],attrs:{"type":"text","name":"userid","maxlength":"20"},domProps:{"value":(_vm.userid)},on:{"input":function($event){if($event.target.composing){ return; }_vm.userid=$event.target.value}}}),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.dupleId('userid')}}},[_c('span',[_vm._v("중복확인")])]),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"box"},[_c('h3',[_vm._v("이메일")]),_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                email: true,
              },"name":"E-mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"text","name":"email","maxlength":"64"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1),_c('div',{staticClass:"box"},[_c('h3',[_vm._v("비밀번호")]),_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                min_length: 8,
              },"name":"비밀번호","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","name":"password","maxlength":"20"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1),_c('div',{staticClass:"box"},[_c('h3',[_vm._v("비밀번호 확인")]),_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                min_length: 8,
                confirmed: 'password',
              },"name":"비밀번호 확인"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.re_password),expression:"re_password"}],attrs:{"type":"password","name":"re_password","maxlength":"20"},domProps:{"value":(_vm.re_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.re_password=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1),_c('div',{staticClass:"box"},[_c('h3',[_vm._v("이름")]),_c('ValidationProvider',{attrs:{"rules":{
                required: true,
              },"name":"이름"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_name),expression:"user_name"}],attrs:{"type":"text","name":"user_name","maxlength":"20"},domProps:{"value":(_vm.user_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.user_name=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1),_c('div',{staticClass:"box"},[_c('h3',[_vm._v("일반 채널 닉네임")]),_c('ValidationProvider',{ref:"nickname",attrs:{"rules":{
                required: true,
                min_length: 3,
                uniqNickname: true,
                vaildNickname: true,
              },"name":"닉네임"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"input type_check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nickname),expression:"nickname"}],attrs:{"type":"text","name":"nickname","maxlength":"10"},domProps:{"value":(_vm.nickname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nickname=$event.target.value}}}),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.dupleNickname('nickname')}}},[_c('span',[_vm._v("중복확인")])]),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"box"},[_c('h3',[_vm._v("생년월일")]),_c('ValidationProvider',{attrs:{"rules":{
                required: true,
              },"name":"생년월일"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"birth-day"},[_c('date-picker',{attrs:{"date":_vm.startTime,"option":_vm.option},on:{"change":_vm.updateBirthday},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"box"},[_c('h3',[_vm._v("성별")]),_c('div',{staticClass:"input"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sex),expression:"sex"}],attrs:{"name":"sex"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.sex=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("선택")]),_c('option',{attrs:{"value":"male"}},[_vm._v("남")]),_c('option',{attrs:{"value":"female"}},[_vm._v("여")])])])])]),_c('div',{staticClass:"mem_btn"},[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.regist.apply(null, arguments)}}},[_vm._v("완 료")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }